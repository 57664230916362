<template>
  <div class="main-cell-container">
    <div
      class="mcc-left"
      :class="{
        '--hoverable': isFacturesTab && !isSplitted && isAccruals,
      }"
    >
      <div
        class="mcc-checkbox"
        :class="{
          '--hoverable': transactionsChecked.length === 0 || !isFacturesTab || !isAccruals,
        }"
      >
        <uds-input-checkbox :is-checked="isChecked" @input="changeChecked" />
      </div>
      <div
        class="sales-item-icon-wrapper"
        :class="{
          '--hoverable': transactionsChecked.length === 0 || !isFacturesTab || !isAccruals,
        }"
        @click="
          isAccruals && !salesItem.isImportedDocument() && !isSent && openSendEstimateInvoiceModal()
        "
      >
        <template v-if="statusIcon">
          <uds-squircle-icon
            :key="statusIcon.icon"
            :icon="statusIcon.icon"
            :color="statusIcon.color"
            :size="'small'"
          />
        </template>
      </div>
    </div>
    <div class="sales-item-title-subtitle">
      <div class="sales-item-title">
        <CommonsTextTooltipIfTextOverflowed :text="salesItemTitle" />
      </div>
      <div class="sales-item-subtitle">
        <CommonsTextTooltipIfTextOverflowed :text="salesItemSubtitle" />
      </div>
    </div>
    <template v-if="isAccruals">
      <span class="sales-item-icon-tooltip-wrapper" style="margin-left: 10px; margin-bottom: 4px">
        <uds-tooltip v-if="isAlreadyProcessByOcr" tooltip-text="Annoté par reconnaissance optique">
          <uds-icon icon-name="document_scanner" color="#7E7E7E" rounded size="small" />
        </uds-tooltip>
      </span>
    </template>
    <uds-tooltip
      v-if="isSent"
      style="margin-left: 10px; margin-bottom: 4px"
      :tooltip-text="`Envoyé par mail ${sendDate ? 'le ' + sendDate : ''} `"
    >
      <uds-icon icon-name="mail_outline" outlined :color="primaryColors.primary500" size="small" />
    </uds-tooltip>

    <uds-tooltip
      v-if="hasARecovery"
      :tooltip-text="recoveryStatusMetadata ? recoveryStatusMetadata.iconLabel : 'Recouvrement'"
      style="margin-left: 10px; margin-bottom: 4px"
    >
      <uds-icon icon-name="build" :color="recoveryStatusMetadata?.color" size="small" />
    </uds-tooltip>

    <span class="sales-item-icon-tooltip-wrapper">
      <uds-tooltip
        v-if="linkedTransactionName"
        :tooltip-text="`Facture rapprochée à la transaction ${linkedTransactionName}`"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0)">
            <path
              d="M6 11H14V13H6V11ZM8 19H4V3H11V8H16V11.1L18 9.1V7L12 1H4C3.46957 1 2.96086 1.21071 2.58579 1.58579C2.21071 1.96086 2 2.46957 2 3V19C2 19.5304 2.21071 20.0391 2.58579 20.4142C2.96086 20.7893 3.46957 21 4 21H8V19ZM6 17H10.1L11 16.1V15H6V17Z"
              :fill="primaryColors.primary500"
            />
            <g clip-path="url(#clip1)">
              <path
                d="M17.5137 21.7288C16.7831 22.4594 15.8092 22.8869 14.8064 22.9173C13.8035 22.9477 12.8538 22.5785 12.1662 21.8908C11.4786 21.2032 11.1093 20.2535 11.1397 19.2506C11.1701 18.2478 11.5976 17.2739 12.3283 16.5433L17.5874 11.2842C18.1187 10.7529 18.827 10.4419 19.5563 10.4198C20.2857 10.3977 20.9764 10.6663 21.4765 11.1664C21.9765 11.6665 22.2451 12.3572 22.223 13.0865C22.2009 13.8158 21.89 14.5241 21.3586 15.0555L17.1012 19.3128C16.7691 19.6449 16.3265 19.8393 15.8706 19.8531C15.4148 19.8669 14.9831 19.699 14.6705 19.3865C14.358 19.0739 14.1902 18.6422 14.204 18.1864C14.2178 17.7306 14.4121 17.2879 14.7442 16.9558L18.5007 13.1993L19.2078 13.9064L15.4513 17.6629C15.3185 17.7957 15.2407 17.9728 15.2352 18.1551C15.2297 18.3375 15.2968 18.5102 15.4218 18.6352C15.5469 18.7602 15.7195 18.8273 15.9019 18.8218C16.0842 18.8163 16.2613 18.7386 16.3941 18.6057L20.6515 14.3483C20.8159 14.1839 20.9483 13.9906 21.041 13.7795C21.1337 13.5683 21.1849 13.3435 21.1917 13.1178C21.1986 12.892 21.1609 12.6699 21.0808 12.464C21.0008 12.2581 20.8799 12.0724 20.7251 11.9177C20.5704 11.7629 20.3847 11.642 20.1788 11.562C19.9729 11.4819 19.7508 11.4442 19.5251 11.4511C19.2994 11.4579 19.0745 11.5092 18.8634 11.6019C18.6522 11.6945 18.4589 11.8269 18.2945 11.9913L13.0354 17.2504C12.504 17.7818 12.1931 18.49 12.171 19.2194C12.1489 19.9487 12.4174 20.6394 12.9175 21.1395C13.4176 21.6396 14.1083 21.9082 14.8376 21.8861C15.567 21.8639 16.2752 21.553 16.8066 21.0217L21.5648 16.2634L22.2719 16.9705L17.5137 21.7288Z"
                :fill="primaryColors.primary500"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="24" height="24" fill="white" />
            </clipPath>
            <clipPath id="clip1">
              <rect
                width="17"
                height="16"
                fill="white"
                transform="translate(5.27197 17) rotate(-45)"
              />
            </clipPath>
          </defs>
        </svg>
      </uds-tooltip>
      <template v-if="annotation.hasCommentary">
        <div class="icon-wrapper">
          <uds-tooltip :tooltip-text="comment">
            <uds-icon icon-name="chat" color="#3C88FF" rounded size="small" />
          </uds-tooltip>
        </div>
      </template>
    </span>
  </div>
</template>

<script setup lang="ts">
import type {
  IEstimateInvoice,
  ReadAccrualInvoice,
  ReadCashBasedInvoice,
  ReadFormattedAccountingTransaction,
} from "@silexpert/core";
import { EstimateStatus, InvoiceStatus } from "@silexpert/core";

import { useModal } from "vue-final-modal";

import SendSalesDocument from "@/components/commons/modals/SendSalesDocument.vue";

const udsColors = getUdsColors();
const societyStore = useSocietyStore();
const thirdPartyStore = useThirdPartiesStore();
const salesStore = useSalesStore();
const { isComptalib } = useBrandsComposable();

const props = defineProps<{
  item: SalesItem;
}>();

const comment = computed(() => {
  const item = props.item as ReadAccrualInvoice;
  return item.comment;
});

const annotation = computed(() => {
  return useAnnotationComposable(props.item);
});

const isAccruals = computed<boolean>(() => societyStore.isAccruals);
const primaryColors = computed(() => getPrimaryColors());

const salesItem = computed(() => useSalesComposable(props.item));
// @ts-expect-error
const isAlreadyProcessByOcr = computed(() => props.item.isAlreadyProcessByOcr);

const formattedThirdParties = thirdPartyStore.getFormattedThirdParties({
  generic: true,
  provider: true,
  customer: true,
  withDeleted: true,
});

const isFacturesTab = computed<boolean>(() => salesStore.queryProperties.tab === "Factures");
const isSplitted = computed<boolean>(
  () =>
    isFacturesTab.value &&
    ((props.item as ReadFormattedAccountingTransaction).entries ?? []).length > 1,
);

const salesItemTitle = computed(() => {
  let invoiceRef;
  let company;
  if (salesItem.value.isFactures()) {
    invoiceRef = (props.item as FacturesItem)?.estimatesInvoice?.reference ?? "";
    if (salesItem.value.isImportedDocument() && !isAccruals.value) {
      company = props.item?.file?.name;
    } else if (salesItem.value.isImportedDocument()) {
      const thirdPartyId = (props.item as ReadAccrualInvoice).entries?.[0]?.idThirdParty ?? null;
      const matchingFormattedThirdParty = formattedThirdParties.find(
        (fTP) => fTP.id === thirdPartyId,
      );
      company = matchingFormattedThirdParty?.label;
    } else {
      company = (props.item as FacturesItem)?.estimatesInvoice?.thirdParty?.company;
    }
  } else if (salesItem.value.isDevisOrBrouillons()) {
    invoiceRef = (props.item as IEstimateInvoice)?.reference;
    company = (props.item as IEstimateInvoice)?.thirdParty?.company;
  }
  return company ? `${invoiceRef ? invoiceRef + " -" : ""} ${company}` : "Nom du client manquant";
});

const salesItemSubtitle = computed(() => {
  const label = salesItem.value.label();
  const hasLabel = isDefined(label) && label !== "";
  if (salesStore.queryProperties.tab === "Devis") {
    if (salesItem.value.isEstimate()) {
      return `Devis ${hasLabel ? "-" : ""} ${label}`;
    } else if (salesItem.value.isOrderForm()) {
      return `BDC ${hasLabel ? "-" : ""} ${label}`;
    }
  }
  if (salesItem.value.isFactures() && salesItem.value.isImportedDocument() && isAccruals.value) {
    return (props.item as ReadAccrualInvoice)?.file?.name ?? "";
  }
  return label;
});

const sendDate = computed(() => {
  let poorlyFormattedSendDate;
  if (salesItem.value.isFactures()) {
    // example: 2021-05-26T00:00:00.000Z
    poorlyFormattedSendDate = (props.item as FacturesItem)?.estimatesInvoice?.mailSent ?? null;
  }
  if (salesItem.value.isDevisOrBrouillons()) {
    poorlyFormattedSendDate = (props.item as IEstimateInvoice)?.mailSent ?? null;
  }
  if (!isDefined(poorlyFormattedSendDate)) {
    return null;
  }
  if (typeof poorlyFormattedSendDate === "string") {
    return (poorlyFormattedSendDate as string).substring(0, 10);
  }
  return poorlyFormattedSendDate.toDateString().substring(0, 10);
});

const linkedTransactionName = computed(() => {
  if (salesItem.value.isCashBasedFactures()) {
    return (props.item as ReadCashBasedInvoice)?.accountingTransaction?.label ?? null;
  }
  return null;
});

const status = computed(() => {
  if (salesItem.value.isFactures()) {
    if (isDefined((props.item as FacturesItem).estimatesInvoice)) {
      return (props.item as FacturesItem).estimatesInvoice?.status ?? null;
    } else {
      return "external";
    }
  } else if (salesItem.value.isDevisOrBrouillons()) {
    return (props.item as IEstimateInvoice).status ?? null;
  }
  return null;
});

const statusIcon = computed(() => {
  if (salesItem.value.isDraft()) {
    return {
      icon: "editnote",
      color: undefined,
    };
  } else {
    switch (status.value) {
      case "external":
        return {
          icon: "DocumentImportedInvoice",
          color: "#1656FF",
        };
      case InvoiceStatus.PAID:
      case EstimateStatus.BILLED:
        return {
          icon: "done",
          color: udsColors.udsFern500,
        };
      case InvoiceStatus.CANCELED:
      case EstimateStatus.EXPIRED:
        return {
          icon: "cancel",
          color: undefined,
        };
      case InvoiceStatus.WAITING:
      case EstimateStatus.WAITING:
        return {
          icon: "accesstime",
          color: udsColors.udsFire500,
        };
      case InvoiceStatus.UNPAID:
        return {
          icon: "warning",
          color: "#E93C3C",
        };
      case EstimateStatus.REFUSED:
        return {
          icon: "refused",
          color: undefined,
        };
      case EstimateStatus.ACCEPTED:
        return {
          icon: "accepted",
          color: udsColors.udsIris500,
        };
      default:
        return {
          icon: "undefined",
          color: undefined,
        };
    }
  }
});

const isSent = computed(() => {
  if (
    salesItem.value.isImportedDocument() &&
    isDefined(props.item.file) &&
    isDefined(props.item.file.commercialMails)
  ) {
    return props.item.file.commercialMails.length > 0;
  } else if (salesItem.value.isFactures()) {
    return isDefined((props.item as FacturesItem)?.estimatesInvoice?.mailSent);
  } else if (salesItem.value.isDevisOrBrouillons()) {
    return isDefined((props.item as IEstimateInvoice)?.mailSent);
  }
  return false;
});

async function openSendEstimateInvoiceModal() {
  const file = props.item.file;

  if (!isDefined(file)) {
    $notifier().open({ type: "error", content: "Fichier inconnu, envoie de mail impossible." });
    return;
  }

  const { open, close } = useModal({
    component: SendSalesDocument,
    attrs: {
      help: `Vous pouvez adresser vos factures et devis à vos clients directement depuis l'application.`,
      svg: isComptalib() ? "ComptalibPaperPlaneSvg" : "SendMailSvg",
      files: [file],
      frenchName: salesItem.value.frenchName(),
      frenchNameWithDefiniteArticle: salesItem.value.frenchNameWithDefiniteArticle(),
      defaultEmail: salesItem.value.thirdPartyEmail(),
      isImported: salesItem.value.isImportedDocument(),
      async onClose() {
        close();
        const link = useBuildRouteFromQueryPropertiesComposable(
          defaultSalesQueryProperties.value,
          {
            ...salesStore.queryProperties,
          },
          "/sales",
        );
        await navigateTo(`${link}#forceRefresh`);
      },
      onCancel() {
        close();
      },
    },
  });
  await open();
}

// Check system
const transactionsChecked = computed<ReadFormattedAccountingTransaction[]>(() => {
  return (salesStore.items ?? []).filter(
    (tr) => tr.id && salesStore.queryProperties.checkedIds.includes(tr.id),
  ) as ReadFormattedAccountingTransaction[];
});

const isChecked = computed(() => {
  return salesStore.queryProperties.checkedIds.includes(props.item.id!);
});

function changeChecked() {
  const oldcheckedIds = ref(salesStore.queryProperties.checkedIds);
  const index = oldcheckedIds.value.findIndex((id: number) => id === props.item.id!);
  let newcheckedIds = [];
  // if the item was already checked, remove it
  if (index > -1) {
    newcheckedIds = [
      ...oldcheckedIds.value.slice(0, index),
      ...oldcheckedIds.value.slice(index + 1),
    ];
  } else {
    newcheckedIds = [...oldcheckedIds.value, props.item.id!];
  }

  return navigateTo(
    useBuildRouteFromQueryPropertiesComposable(
      defaultSalesQueryProperties.value,
      {
        ...salesStore.queryProperties,
        itemId: null,
        checkedIds: newcheckedIds as number[],
      },
      "/sales",
    ),
  );
}

// Recovery
const hasARecovery = computed(() => props.item.file && props.item.file.paymentRecovery);

const { getStatusMetadata } = useRecoveryComposable();

const recoveryStatusMetadata = computed(() =>
  props.item.file?.paymentRecovery
    ? getStatusMetadata(props.item.file.paymentRecovery.status)
    : null,
);
</script>

<style lang="scss" scoped>
.main-cell-container {
  display: flex;
  align-items: center;
  height: 100%;

  .sales-item-icon-wrapper {
    min-width: 70px;
    max-width: 70px;

    display: none;

    &.--hoverable {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .mcc-checkbox {
    min-width: 70px;
    max-width: 70px;
    padding-left: $uds-spacing-1;
    margin-right: -$uds-spacing-1;

    display: flex;
    justify-content: center;
    align-items: center;

    &.--hoverable {
      display: none;
    }
  }

  .mcc-left {
    &.--hoverable:hover {
      .mcc-checkbox {
        display: flex;

        justify-content: center;
        align-items: center;
      }
      .sales-item-icon-wrapper {
        display: none;
      }
    }
  }

  .sales-item-title-subtitle {
    width: auto;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    .sales-item-title {
      // @extend .ds-content-book;
      color: $uds-neutral-900;
    }

    .sales-item-subtitle {
      margin-top: 3px;

      // @extend .ds-content-book;
      color: $uds-neutral-800;

      display: flex;

      .arrow-icon {
        height: 17px;
        margin-left: 5px;
      }
    }
  }

  .sales-item-icon-tooltip-wrapper {
    font-size: 20px;
    margin-left: 10px;

    .icon-document {
      margin-left: 10px;
      font-size: 20px;
    }
  }
}

.nudge-ocr {
  width: 20px;
}
</style>

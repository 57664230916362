<template>
  <uds-banner :closable="!isLoading" @close="closeMessage()">
    <template #content>
      <div class="content-wrapper">
        <div class="content-image">
          <SvgComptalibCreditCardsSvg v-if="brand.isComptalib()" />
          <SvgCreditCardsSvg v-else />
        </div>
        <div class="content-description">
          <div class="uds-headers --h2">
            Encaissez vos clients par carte bancaire avec
            <span class="primary-color">{{ brand.getBrandName() }}</span>
          </div>
          <div class="uds-paragraph">
            Ajouter un lien de paiement par carte bancaire sur vos factures pour vous faire payer
            plus rapidement.<br >
            Le service de paiement est sécurisé et crypté au même niveau qu’un service bancaire avec
            notre partenaire stripe.
          </div>
          <uds-main-button size="small" @click="navigateTo('/settings/billing')"
            >Configurer</uds-main-button
          >
        </div>
      </div>
    </template>
  </uds-banner>
</template>

<script setup lang="ts">
import { VisualizedInformationState } from "@silexpert/core";

const brand = useBrandsComposable();
const societyStore = useSocietyStore();
const isLoading = ref(false);

async function closeMessage() {
  try {
    isLoading.value = true;
    await $silex().visualizedInformation.updateOrCreate(societyStore.society!.id!, {
      key: VisualizedInformationKey.CASH_IN_YOUR_CUSTOMERS_MESSAGE_CLOSED,
      state: VisualizedInformationState.APPROVED,
    });
    societyStore.fetchVisualizedInformations(societyStore.society!.id!);
  } catch (error) {
    $notifier().open({ content: apiErrorToString(error) });
  } finally {
    isLoading.value = false;
  }
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  flex-direction: row;
  .content-image {
    width: 200px;
    margin: auto;
    text-align: center;
  }

  .content-description {
    padding: $uds-spacing-0_5;
    display: flex;
    flex-direction: column;
    gap: $uds-spacing-1;
    justify-content: flex-start;
    align-items: flex-start;
    :deep(.uds-paragraph) {
      color: $uds-neutral-800;
    }
  }
}
</style>

<template>
  <div class="first-item-container">
    <div class="first-item-text">
      <div class="uds-headers --h2">Créez votre premier brouillon</div>
      <div class="uds-paragraph first-item-subtitle">Préparez vos factures en avance.</div>
    </div>
    <div class="first-item-svg">
      <div>
        <img v-if="isComptalib()" src="~/assets/img/firstDraft1Comptalib.png" >
        <SvgOnBoardingDocumentEditSvg v-else />
        <div class="uds-paragraph">Créez des brouillons de factures en quelques clics</div>
      </div>
      <div>
        <img v-if="isComptalib()" src="~/assets/img/firstDraft2Comptalib.png" >
        <SvgOnBoardingDocumentRotationSvg v-else />
        <div class="uds-paragraph">Transformez vos brouillon en facture en un seul clic.</div>
      </div>
    </div>
    <div class="first-item-buttons">
      <uds-main-button @click="navigateTo(`/sales/form/draft`)">Commencer</uds-main-button>
    </div>
  </div>
</template>

<script setup lang="ts">
const { isComptalib } = useBrandsComposable();
</script>

<template>
  <div class="shc-container" :class="{ 'visibility-hidden': areStatisticsBeingFetched }">
    <div class="shc-header">Factures créées</div>
    <div class="shc-content">
      <div class="left-cards">
        <uds-amount-card
          :color="primaryColor"
          label="Total"
          :count="totalcount"
          :amount="totalamount"
          @click="setStatus(null)"
        >
          <template #icon>
            <uds-squircle-icon
              icon="DocumentSalesInvoiceAndLibraryFile"
              :color="primaryColor"
              :size="'small'"
            />
          </template>
        </uds-amount-card>
      </div>
      <div class="right-cards">
        <uds-amount-card
          :color="udsColors.udsSuccess500"
          label="Encaissées"
          :count="paidCount"
          :amount="paidAmount"
          @click="setStatus(InvoiceStatus.PAID)"
        >
          <template #icon>
            <uds-squircle-icon icon="done" :color="udsColors.udsFern500" :size="'small'" />
          </template>
        </uds-amount-card>
        <uds-amount-card
          :color="udsColors.udsFire500"
          label="En attente"
          :count="waitingCount"
          :amount="waitingAmount"
          @click="setStatus(InvoiceStatus.WAITING)"
        >
          <template #icon>
            <uds-squircle-icon icon="accesstime" :color="udsColors.udsFire500" :size="'small'" />
          </template>
        </uds-amount-card>
        <uds-amount-card
          :color="udsColors.udsError500"
          label="En retard"
          :count="unpaidCount"
          :amount="unpaidAmount"
          @click="setStatus(InvoiceStatus.UNPAID)"
        >
          <template #icon>
            <uds-squircle-icon icon="warning" color="#E93C3C" :size="'small'" />
          </template>
        </uds-amount-card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { InvoiceStatus } from "@silexpert/core";

const salesStore = useSalesStore();

const udsColors = getUdsColors();
const primaryColor = computed(() => getPrimaryColors().primary500);
const areStatisticsBeingFetched = computed(() => salesStore.stats === null);
const invoicesStatistics = computed(() => salesStore.stats?.invoices ?? null);

const paidCount = computed(() => invoicesStatistics.value?.paid?.count ?? 0);
const unpaidCount = computed(() => invoicesStatistics.value?.unpaid?.count ?? 0);
const waitingCount = computed(() => invoicesStatistics.value?.waiting?.count ?? 0);

const paidAmount = computed(() => invoicesStatistics.value?.paid?.amount ?? 0);
const unpaidAmount = computed(() => invoicesStatistics.value?.unpaid?.amount ?? 0);
const waitingAmount = computed(() => invoicesStatistics.value?.waiting?.amount ?? 0);

const totalcount = computed(() => paidCount.value + unpaidCount.value + waitingCount.value);
const totalamount = computed(() => paidAmount.value + unpaidAmount.value + waitingAmount.value);

function setStatus(status: null | InvoiceStatus) {
  const newLink = useBuildRouteFromQueryPropertiesComposable(
    defaultSalesQueryProperties.value,
    {
      ...salesStore.queryProperties,
      status,
      itemId: null,
      checkedIds: [],
      page: 1,
    },
    "/sales",
  );
  return navigateTo(`${newLink}`);
}
</script>
<style lang="scss" scoped>
.shc-container {
  width: 100%;
  &.visibility-hidden {
    visibility: hidden;
  }

  .shc-header {
    font-weight: $uds-weight-500;
    font-size: 16px;
    line-height: 20px;
    color: $uds-neutral-800;
  }
  .shc-content {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .right-cards {
      display: flex;

      > * {
        &:not(:first-child) {
          margin-left: 15px;
        }
      }
    }
  }
}
</style>

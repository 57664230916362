<template>
  <div class="scb-container">
    <template v-if="tab === 'Factures'">
      <uds-main-button
        v-if="isAccruals && isInToAnnotateView && !isLoading"
        type="secondary"
        size="small"
        icon-name="fullscreen"
        @click="annotateFullPage"
      >
        Annoter en mode plein écran
      </uds-main-button>
      <uds-main-button
        size="small"
        icon-name="upload"
        type="secondary"
        @click="openModalImportInvoice"
      >
        {{ isAccruals ? "Importer" : "Importer une facture" }}
      </uds-main-button>

      <uds-main-button
        size="small"
        icon-name="add"
        type="secondary"
        @click="navigateTo(`/sales/form/${EstimateInvoiceType.AVOIR}`)"
      >
        Créer un avoir
      </uds-main-button>

      <uds-main-button
        size="small"
        icon-name="add"
        @click="navigateTo(`/sales/form/${EstimateInvoiceType.FACTURE}`)"
      >
        Créer une facture
      </uds-main-button>
    </template>
    <template v-if="tab === 'Devis'">
      <uds-main-button
        size="small"
        icon-name="add"
        type="secondary"
        @click="navigateTo(`/sales/form/${EstimateInvoiceType.BON}`)"
      >
        Créer un bon de commande
      </uds-main-button>
      <uds-main-button
        size="small"
        icon-name="add"
        @click="navigateTo(`/sales/form/${EstimateInvoiceType.DEVIS}`)"
      >
        Créer un devis
      </uds-main-button>
    </template>

    <template v-if="tab === 'Brouillons'">
      <uds-main-button size="small" icon-name="add" @click="navigateTo(`/sales/form/draft`)">
        Créer un brouillon
      </uds-main-button>
    </template>
    <template v-if="shouldDisplayRecoveryButton">
      <uds-main-button class="button-recover" size="small" @click="shouldDisplayDialog = true">
        Recouvrez vos impayés
      </uds-main-button>
      <CommonsSimpleModal
        :is-open="shouldDisplayDialog"
        title="Recouvrement de vos factures impayées"
        text="Retard de paiement ? Relances sans réponses ?  Lancez votre démarche de recouvrement directement en ligne sur Legalstart."
        accept-button-text="Démarrer la procédure"
        :is-loading="isDisplayDialogLoading"
        @close-reject="shouldDisplayDialog = false"
        @close-accept="sendRecoveryMail()"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { EstimateInvoiceType } from "@silexpert/core";
import { useModal } from "vue-final-modal";
import cloneDeep from "lodash-es/cloneDeep";
import TypeImportModal from "@/components/commons/modals/TypeImportModal.vue";
import ImportInvoice from "@/components/commons/modals/ImportInvoiceModal.vue";

const salesStore = useSalesStore();
const societyStore = useSocietyStore();
const userStore = useUsersStore();
const annotateStore = useAnnotateStore();
const { isComptaStart } = useBrandsComposable();

const shouldDisplayDialog = ref<boolean>(false);
const isDisplayDialogLoading = ref<boolean>(false);

const tab = computed<string | null>(() => salesStore.queryProperties.tab);
const isLoading = computed<boolean>(() => salesStore.isLoading);
const isAccruals = computed<boolean>(() => societyStore.isAccruals);

const isInToAnnotateView = computed<boolean>(() => salesStore.queryProperties.toAnnotate);
const shouldDisplayRecoveryButton = computed<boolean>(
  () => isComptaStart() && tab.value === "Factures",
);

const society = computed(() => societyStore.society!);
const user = computed(() => userStore.user!);

async function sendRecoveryMail() {
  window.open("https://www.legalstart.fr/recouvrement/", "_blank");

  isDisplayDialogLoading.value = true;
  await $silex()
    .mail.create({
      fromName: "Clementine",
      fromAddress: "app@compta-clementine.fr",
      to: "recouvrement@yolaw.fr",
      subject: " Comptastart - un abonné souhaite recouvrer un impayé",
      textHtml: `Un abonné Comptastart souhaite recouvrer un impayé. Avant de le contacter, vérifier s’il n’a pas déjà converti. Sinon appeler pour proposer une réduction.
                - ${society.value.name}
                - ${user.value.username}
                - ${user.value.phone}`,
      isSent: true,
    })
    .then(() => {
      isDisplayDialogLoading.value = false;
      shouldDisplayDialog.value = false;
    })
    .catch((error: any) => {
      $notifier().open({ type: "error", content: apiErrorToString(error) });
      isDisplayDialogLoading.value = false;
    });
}

async function openModalImportInvoice() {
  if (isAccruals.value) {
    const { open, close } = useModal({
      component: TypeImportModal,
      attrs: {
        onClose() {
          close();
        },
      },
    });
    await open();
  } else {
    const { open, close } = useModal({
      component: ImportInvoice,
      attrs: {
        isSales: true,
        async onClose() {
          close();
          const link = useBuildRouteFromQueryPropertiesComposable(
            defaultSalesQueryProperties.value,
            {
              ...salesStore.queryProperties,
              page: 1,
              limit: 25,
            },
            "/sales",
          );
          await navigateTo(`${link}#forceRefresh`);
        },
        onCancel() {
          close();
        },
      },
    });
    await open();
  }
}

function annotateFullPage() {
  annotateStore.importedTransactions = cloneDeep(toRaw(salesStore.items)) ?? [];
  annotateStore.isExpenseNote = false;
  return navigateTo("/annotateAfterImport");
}
</script>

<style lang="scss" scoped>
.scb-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  :not(:first-child) {
    margin-left: 15px;
  }
}

.uds-main-button {
  .icon {
    margin-right: $uds-spacing-1;
  }
}
</style>

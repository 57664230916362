<template>
  <div class="status-cell-container">
    <uds-tooltip
      v-if="metadataTag && metadataTag.tooltipText"
      :tooltip-text="metadataTag.tooltipText"
    >
      <uds-tag :color="metadataTag.color">
        {{ metadataTag.text }}
      </uds-tag>
    </uds-tooltip>
    <template v-else-if="metadataTag">
      <uds-tag :color="metadataTag.color">
        {{ metadataTag.text }}
      </uds-tag>
    </template>
    <template v-if="shoudlDisplayIcons">
      <uds-tooltip
        v-if="hasSalesItemAnActiveRepetition"
        :tooltip-text="`Récurrence ${salesItemFrequencyName}`"
      >
        <uds-icon icon-name="history" />
      </uds-tooltip>
      <uds-tooltip v-if="isRelanceActive" tooltip-text="Relance">
        <uds-icon icon-name="redo" />
      </uds-tooltip>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { IEstimateInvoice } from "@silexpert/core";
import { InvoiceStatus, EstimateStatus, RecurrenceList } from "@silexpert/core";

const dayjs = useDayjs();
type InvoiceItem = FacturesItem & {
  // estimatesInvoice can not be null/undefined
  estimatesInvoice: IEstimateInvoice;
};

const props = withDefaults(
  defineProps<{
    item: SalesItem;
    shoudlDisplayIcons?: boolean;
  }>(),
  {
    shoudlDisplayIcons: true,
  },
);

const salesItem = computed(() => useSalesComposable(props.item));

const status = computed(() => {
  if (salesItem.value.isFactures()) {
    if (isDefined((props.item as FacturesItem).estimatesInvoice)) {
      return (props.item as FacturesItem).estimatesInvoice?.status ?? null;
    } else {
      return "external";
    }
  } else if (salesItem.value.isDevisOrBrouillons()) {
    return (props.item as IEstimateInvoice).status ?? null;
  }
  return null;
});

const metadataTag = computed(() => {
  const item = props.item;
  // trick to remove all info after the day, because a 22 hour difference (for example) would not return 1 after using .diff(x, "days") #AndreLewin
  const now = dayjs(dayjs().format("YYYY-MM-DD"));
  let dueDate = null;
  if (salesItem.value.isFactures()) {
    dueDate = dayjs((item as FacturesItem)?.estimatesInvoice?.dueDate);
  } else {
    dueDate = dayjs((item as IEstimateInvoice)?.dueDate);
  }

  const dueDiff = dueDate.diff(now, "days");
  const dueInvoiceText = `${dueDiff} jour${dueDiff > 1 ? "s" : ""}`;
  const expiredInvoiceText = `${-dueDiff} jour${-dueDiff > 1 ? "s" : ""}`;

  const expirationDate = dayjs((item as IEstimateInvoice)?.dueDate);
  const expirationDiff = expirationDate.diff(now, "days");
  const expiredEstimateText = `${expirationDiff} jour${expirationDiff > 1 ? "s" : ""}`;

  switch (status.value) {
    // Factures
    case "external":
      return {
        text: "Importée",
        color: "neutral",
      };
    case InvoiceStatus.PAID:
      return {
        text: "Encaissé",
        color: "success",
      };
    case InvoiceStatus.CANCELED:
      return {
        text: "Annulé",
        color: "neutral",
      };
    case InvoiceStatus.WAITING:
      return {
        text: "En attente",
        color: "fire",
        tooltipText: `Échéance dans ${dueInvoiceText}`,
      };
    case InvoiceStatus.UNPAID:
      return {
        text: "En retard",
        color: "error",
        tooltipText: `Dépassée depuis ${expiredInvoiceText}`,
      };
    // Devis
    case EstimateStatus.REFUSED:
      return {
        text: "Refusé",
        color: "neutral",
      };
    case EstimateStatus.ACCEPTED:
      return {
        text: "Accepté",
        color: "iris",
      };
    case EstimateStatus.WAITING:
      return {
        text: "En attente",
        color: "fire",
        tooltipText: `Expire dans ${expiredEstimateText}`,
      };
    case EstimateStatus.EXPIRED:
      return {
        text: "Expiré",
        color: "neutral",
      };
    case EstimateStatus.BILLED:
      return {
        text: "Facturé",
        color: "success",
      };
    default:
      return {
        text: "Inconnue",
        color: "neutral",
      };
  }
});

const hasSalesItemAnActiveRepetition = computed(() => {
  if (salesItem.value.isFactures() && !salesItem.value.isImportedDocument()) {
    const estimateInvoice = (props.item as InvoiceItem).estimatesInvoice;
    return isDefined(estimateInvoice) && isDefined(estimateInvoice.recurrence)
      ? estimateInvoice.recurrence.isActive
      : false;
  }
  return false;
});

const salesItemFrequencyName = computed(() => {
  if (salesItem.value.isFactures() && !salesItem.value.isImportedDocument()) {
    const estimateInvoice = (props.item as InvoiceItem).estimatesInvoice;
    const matchingType = RecurrenceList.find((r) => r.id === estimateInvoice.recurrence?.type);
    return isDefined(estimateInvoice.recurrence) ? matchingType?.label.toLowerCase() : null;
  }
  return null;
});

const isRelanceActive = computed(() => {
  let estimateInvoice: IEstimateInvoice | null = null;
  if (salesItem.value.isDevisOrBrouillons()) {
    estimateInvoice = props.item as IEstimateInvoice;
  } else if (salesItem.value.isFactures()) {
    estimateInvoice = (props.item as FacturesItem).estimatesInvoice ?? null;
  }
  if (
    isDefined(estimateInvoice) &&
    estimateInvoice.isPaid === false &&
    estimateInvoice.isCanceled === false &&
    isDefined(estimateInvoice.reminder)
  ) {
    return true;
  }
  return false;
});
</script>

<style lang="scss" scoped>
.status-cell-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  color: $uds-neutral-800;
}
</style>

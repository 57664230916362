<template>
  <svg
    width="180"
    height="180"
    viewBox="0 0 180 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M160.05 21.3887H85.7851C83.7529 21.3887 82.0508 23.1946 82.0508 25.4711V137.318C82.0508 139.594 83.7529 141.4 85.7851 141.4H160.05C162.082 141.4 163.785 139.594 163.785 137.318V25.4711C163.785 23.1946 162.082 21.3887 160.05 21.3887ZM85.7851 21.0488H160.05C162.296 21.0488 164.124 23.0336 164.124 25.4711V137.318C164.124 139.755 162.296 141.74 160.05 141.74H85.7851C83.5395 141.74 81.7109 139.755 81.7109 137.318V25.4711C81.7109 23.0336 83.5395 21.0488 85.7851 21.0488Z"
      fill="#333333"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M93.4975 72.4551L152.337 72.4551V72.7949L93.4975 72.7949V72.4551Z"
      fill="#333333"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M93.4975 81.2285L152.337 81.2285V81.5684L93.4975 81.5684V81.2285Z"
      fill="#333333"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M93.4975 89.9961L152.337 89.9961V90.3359L93.4975 90.3359V89.9961Z"
      fill="#333333"
    />
    <rect
      x="123.302"
      y="32.4102"
      width="28.8642"
      height="8.55477"
      :fill="primaryColors.primary100"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M151.997 32.5312H123.439V40.6227H151.997V32.5312ZM123.1 32.1914V40.9625H152.337V32.1914H123.1Z"
      fill="#333333"
    />
    <rect x="15.876" y="32.4082" width="82.3711" height="120.542" rx="3" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M94.2144 32.5H19.9501C17.9179 32.5 16.2158 34.3059 16.2158 36.5824V148.428C16.2158 150.704 17.9179 152.51 19.9501 152.51H94.2144C96.2466 152.51 97.9487 150.704 97.9487 148.428V36.5824C97.9487 34.3059 96.2466 32.5 94.2144 32.5ZM19.9501 32.1602H94.2144C96.46 32.1602 98.2885 34.145 98.2885 36.5824V148.428C98.2885 150.865 96.46 152.85 94.2144 152.85H19.9501C17.7045 152.85 15.876 150.865 15.876 148.428V36.5824C15.876 34.145 17.7045 32.1602 19.9501 32.1602Z"
      fill="#333333"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.6622 83.5645L86.501 83.5645V83.9043L27.6622 83.9043V83.5645Z"
      fill="#333333"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.6622 92.3379L86.501 92.3379V92.6777L27.6622 92.6777V92.3379Z"
      fill="#333333"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.6622 101.104L86.501 101.104V101.443L27.6622 101.443V101.104Z"
      fill="#333333"
    />
    <path
      d="M119.648 134.877C119.648 147.317 109.563 157.402 97.1226 157.402C84.6824 157.402 74.5977 147.317 74.5977 134.877C74.5977 122.436 84.6824 112.352 97.1226 112.352C109.563 112.352 119.648 122.436 119.648 134.877Z"
      :fill="primaryColors.primary50"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M97.1226 156.992C109.337 156.992 119.238 147.091 119.238 134.877C119.238 122.663 109.337 112.761 97.1226 112.761C84.9086 112.761 75.0072 122.663 75.0072 134.877C75.0072 147.091 84.9086 156.992 97.1226 156.992ZM97.1226 157.402C109.563 157.402 119.648 147.317 119.648 134.877C119.648 122.436 109.563 112.352 97.1226 112.352C84.6824 112.352 74.5977 122.436 74.5977 134.877C74.5977 147.317 84.6824 157.402 97.1226 157.402Z"
      fill="#333333"
    />
    <path
      d="M101.079 140.829C101.514 141.264 102.216 141.264 102.651 140.829L107.769 135.711C108.204 135.276 108.204 134.574 107.769 134.139L102.651 129.021C102.216 128.586 101.514 128.586 101.079 129.021C100.655 129.456 100.644 130.169 101.079 130.604L104.29 133.816H91.8351C91.2218 133.816 90.72 134.317 90.72 134.931C90.72 135.544 91.2218 136.046 91.8351 136.046H104.29L101.079 139.257C100.644 139.692 100.644 140.394 101.079 140.829ZM87.3748 141.621C87.9881 141.621 88.4899 141.119 88.4899 140.506V129.355C88.4899 128.742 87.9881 128.24 87.3748 128.24C86.7615 128.24 86.2598 128.742 86.2598 129.355V140.506C86.2598 141.119 86.7615 141.621 87.3748 141.621Z"
      :fill="primaryColors.primary300"
    />
    <path
      d="M65.1387 159.728L67.3183 162.807L76.9806 155.908L65.1387 156.717V159.728Z"
      fill="#333333"
    />
    <path
      d="M65.0264 159.772L67.2959 162.963L77.3626 155.795L65.0264 156.649V159.772ZM65.2511 156.829L76.5762 156.065L67.3408 162.648L65.2511 159.705V156.829Z"
      fill="#333333"
    />
    <path
      d="M66.9121 151.587L76.1025 164.216L84.0121 148.711V145.723L66.9121 148.576V151.587Z"
      fill="#333333"
    />
    <path
      d="M66.7998 151.612L76.125 164.42L84.1245 148.781L84.147 148.758V145.59L66.7998 148.489V151.612ZM67.0245 148.668L83.8998 145.837V148.691L76.0801 163.993L67.0245 151.545V148.668Z"
      fill="#333333"
    />
    <path
      d="M65.1386 156.73L67.3174 159.785L76.9591 152.91L74.7803 149.854L65.1386 156.73Z"
      fill="white"
    />
    <path
      d="M64.9805 156.696L67.2949 159.932L77.137 152.921L74.8225 149.686L64.9805 156.696ZM67.3399 159.64L65.2951 156.764L74.7551 150.023L76.7999 152.899L67.3399 159.64Z"
      fill="#333333"
    />
    <path d="M76.1035 161.209L84.0131 145.727L66.9131 148.58L76.1035 161.209Z" fill="white" />
    <path
      d="M76.1261 161.412L84.2154 145.57L66.7109 148.491L76.1261 161.412ZM76.1036 160.985L67.1154 148.649L83.811 145.862L76.1036 160.985Z"
      fill="#333333"
    />
    <path
      d="M67.5204 21.4648L32.8827 21.6314C29.3856 21.6314 26.5547 24.7121 26.5547 28.3757L26.7212 46.6105C26.7212 50.2741 29.6354 53.2715 33.1325 53.2715H42.7078L48.8693 59.6828C49.7852 60.682 51.284 60.682 52.1999 59.6828L58.2781 53.1883H67.7702C71.2672 53.1883 74.0982 50.1075 74.0982 46.4439L73.9316 28.2092C73.9316 24.4623 71.0175 21.4648 67.5204 21.4648Z"
      fill="#6A9FF6"
    />
    <path
      d="M67.5207 21.0488L32.883 21.2154C31.1345 21.2154 29.4692 21.9647 28.2203 23.2137C26.888 24.5459 26.1387 26.461 26.1387 28.376L26.3052 46.6108C26.3052 50.5242 29.3859 53.6882 33.1328 53.6882H42.5416L48.6198 60.0162C49.1194 60.599 49.8688 60.8488 50.6181 60.8488C51.3675 60.8488 52.0336 60.5158 52.6165 60.0162L58.5282 53.6882H67.8537C69.6022 53.6882 71.2676 52.9388 72.5165 51.6898C73.8487 50.3576 74.5981 48.4426 74.5981 46.5275L74.4316 28.2928C74.3483 24.2961 71.2676 21.0488 67.5207 21.0488ZM71.8504 50.9405C70.7679 52.0229 69.3525 52.689 67.7705 52.689H58.1119L51.9504 59.2668C51.6173 59.6831 51.1177 59.8497 50.6181 59.8497C50.1186 59.8497 49.619 59.5999 49.2859 59.2668L42.9579 52.689H33.2161C29.9688 52.689 27.3044 49.858 27.2211 46.4442L27.0546 28.2095C27.0546 26.461 27.7207 24.7957 28.8864 23.63C29.9688 22.5476 31.3843 21.8815 32.9663 21.8815L67.6039 21.7149C70.8512 21.7149 73.5157 24.5459 73.5989 27.9597L73.7655 46.1944C73.6822 48.1095 73.016 49.7748 71.8504 50.9405Z"
      fill="#6A9FF6"
    />
    <path
      d="M46.9386 41.9542L43.4386 38.4542C43.0486 38.0642 42.4286 38.0642 42.0386 38.4542C41.6486 38.8442 41.6486 39.4642 42.0386 39.8542L46.2286 44.0442C46.6186 44.4342 47.2486 44.4342 47.6386 44.0442L58.2386 33.4542C58.6286 33.0642 58.6286 32.4442 58.2386 32.0542C57.8486 31.6642 57.2286 31.6642 56.8386 32.0542L46.9386 41.9542Z"
      fill="white"
    />
  </svg>
</template>

<script setup lang="ts">
const primaryColors = computed(() => {
  return getPrimaryColors();
});
</script>

<template>
  <div class="shc-container" :class="{ 'visibility-hidden': areStatisticsBeingFetched }">
    <div class="shc-header">Devis créés</div>
    <div class="shc-content">
      <div class="left-cards">
        <uds-amount-card
          :color="primaryColor"
          label="Total"
          :count="totalcount"
          :amount="totalamount"
          @click="setStatus(null)"
        >
          <template #icon>
            <uds-squircle-icon
              icon="DocumentSalesInvoiceAndLibraryFile"
              :color="primaryColor"
              :size="'small'"
            />
          </template>
        </uds-amount-card>
      </div>
      <div class="right-cards">
        <uds-amount-card
          :color="udsColors.udsIris500"
          label="Acceptés"
          :count="acceptedCount"
          :amount="acceptedAmount"
          @click="setStatus(EstimateStatus.ACCEPTED)"
        >
          <template #icon>
            <uds-squircle-icon icon="accepted" :color="udsColors.udsIris500" :size="'small'" />
          </template>
        </uds-amount-card>
        <uds-amount-card
          :color="udsColors.udsFire500"
          label="En attente"
          :count="waitingCount"
          :amount="waitingAmount"
          @click="setStatus(EstimateStatus.WAITING)"
        >
          <template #icon>
            <svg
              width="18"
              height="21"
              viewBox="0 0 18 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 2H10.82C10.4 0.84 9.3 0 8 0C6.7 0 5.6 0.84 5.18 2H2C0.9 2 0 2.9 0 4V19C0 20.1 0.9 21 2 21H8.11C7.52 20.43 7.04 19.75 6.69 19H2V4H4V5C4 6.1 4.9 7 6 7H10C11.1 7 12 6.1 12 5V4H14V9.08C14.71 9.18 15.38 9.39 16 9.68V4C16 2.9 15.1 2 14 2ZM8 4C7.45 4 7 3.55 7 3C7 2.45 7.45 2 8 2C8.55 2 9 2.45 9 3C9 3.55 8.55 4 8 4ZM13 11C10.24 11 8 13.24 8 16C8 18.76 10.24 21 13 21C15.76 21 18 18.76 18 16C18 13.24 15.76 11 13 11ZM14.29 18L12.64 16.35C12.55 16.26 12.49 16.13 12.49 16V13.51C12.49 13.23 12.71 13.01 12.99 13.01C13.27 13.01 13.49 13.23 13.49 13.51V15.8L14.99 17.3C15.19 17.5 15.19 17.81 14.99 18.01C14.8 18.2 14.49 18.2 14.29 18Z"
                :fill="udsColors.udsFire500"
              />
            </svg>
          </template>
        </uds-amount-card>
        <uds-amount-card
          color="#7788aa"
          label="Refusé"
          :count="refusedCount"
          :amount="refusedAmount"
          @click="setStatus(EstimateStatus.REFUSED)"
        >
          <template #icon>
            <uds-squircle-icon icon="refused" color="#7788aa" :size="'small'" />
          </template>
        </uds-amount-card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { EstimateStatus } from "@silexpert/core";

const salesStore = useSalesStore();

const udsColors = getUdsColors();
const primaryColor = computed(() => getPrimaryColors().primary500);
const areStatisticsBeingFetched = computed(() => salesStore.stats === null);
const preSalesStatistics = computed(() => salesStore.stats?.preSales ?? null);

const acceptedCount = computed(() => preSalesStatistics.value?.accepted?.count ?? 0);
const refusedCount = computed(() => preSalesStatistics.value?.refused?.count ?? 0);
const waitingCount = computed(() => preSalesStatistics.value?.waiting?.count ?? 0);

const acceptedAmount = computed(() => preSalesStatistics.value?.accepted?.amount ?? 0);
const refusedAmount = computed(() => preSalesStatistics.value?.refused?.amount ?? 0);
const waitingAmount = computed(() => preSalesStatistics.value?.waiting?.amount ?? 0);

const totalcount = computed(() => acceptedCount.value + refusedCount.value + waitingCount.value);
const totalamount = computed(
  () => acceptedAmount.value + refusedAmount.value + waitingAmount.value,
);

function setStatus(status: null | EstimateStatus) {
  const newLink = useBuildRouteFromQueryPropertiesComposable(
    defaultSalesQueryProperties.value,
    {
      ...salesStore.queryProperties,
      status,
      itemId: null,
      checkedIds: [],
      page: 1,
    },
    "/sales",
  );
  return navigateTo(`${newLink}`);
}
</script>
<style lang="scss" scoped>
.shc-container {
  width: 100%;
  &.visibility-hidden {
    visibility: hidden;
  }

  .shc-header {
    font-weight: $uds-weight-500;
    font-size: 16px;
    line-height: 20px;
    color: $uds-neutral-800;
  }
  .shc-content {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .right-cards {
      display: flex;

      > * {
        &:not(:first-child) {
          margin-left: 15px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="tag-cell-container">
    <uds-tag v-if="text" color="neutral">
      {{ text }}
    </uds-tag>
  </div>
</template>

<script setup lang="ts">
import type { ReadAccrualInvoice} from "@silexpert/core";
import { RoleAllowedToLogInAsClient } from "@silexpert/core";
const roleComposable = useRolesComposable();

const props = defineProps<{
  item: ReadAccrualInvoice;
}>();

const havePermission = computed(() => {
  return roleComposable.hasOneOfTheseRoles(RoleAllowedToLogInAsClient);
});

const text = computed(() => {
  if (
    isDefined(props.item.dateVerified) &&
    isDefined(props.item.exported) &&
    havePermission.value
  ) {
    return "Exporté et Révisé";
  } else if (isDefined(props.item.dateVerified) && havePermission.value) {
    return "Révisé";
  } else if (
    isDefined(props.item.exported) ||
    (isDefined(props.item.dateVerified) && havePermission.value)
  ) {
    return "Exporté";
  }
  return null;
});
</script>

<style lang="scss" scoped>
.tag-cell-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>

<template>
  <div
    class="category-cell-container"
    :class="{
      '--colored': label === 'Choisir la catégorie',
    }"
  >
    <CommonsTextTooltipIfTextOverflowed :text="label" />
  </div>
</template>

<script setup lang="ts">
import type { ReadAccrualInvoice } from "@silexpert/core";

const categoryStore = useCategoryStore();

const props = defineProps<{
  item: ReadAccrualInvoice;
}>();

const salesItem = computed(() => useSalesComposable(props.item));
const isSliced = computed<boolean>(() => (props.item.entries?.length ?? 0) > 1);

const matchingCategory = computed(() =>
  props.item.entries?.[0]?.idAccount
    ? categoryStore.getMatchingCategory(props.item.entries[0].idAccount) ?? null
    : null,
);

const matchingCategoryName = computed(() => matchingCategory.value?.name ?? null);

const label = computed<string>(() => {
  if (isSliced.value) {
    return "Plusieurs catégories";
  }

  if (isDefined(matchingCategoryName.value)) {
    return matchingCategoryName.value;
  }

  if (salesItem.value.isLocked()) {
    return "Catégorie manquante";
  }

  return "Choisir la catégorie";
});
</script>

<style lang="scss" scoped>
.category-cell-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-size: 16px;
  line-height: 20px;
  color: $uds-neutral-800;

  &.--colored {
    color: $uds-primary-500;
  }

  & > * {
    overflow: hidden;
  }
}
</style>

<template>
  <div ref="sales">
    <LayoutMainAndSidePanel
      :is-side-panel-opened="isSidePanelOpened"
      state="sales"
      drawer-position="sales"
      :config-filters="[
        'search',
        'filtersAndFlags',
        'numberPerPage',
        'paginationArrows',
        'calendar',
        'toAnnotate',
      ]"
      :workable-width="isSidePanelOpened ? workableWidth - 596 : workableWidth"
    >
      <template #top-button>
        <template v-if="isAccruals && tab === 'Factures'">
          <CommonsToAnnotateCounter source="sales" />
        </template>
        <div style="text-wrap: nowrap; margin-left: auto">
          <SalesCreationButtons />
        </div>
      </template>

      <template #top-content>
        <div v-if="hasAnyItem === true" class="sales-top-container">
          <SalesCashInYourCustomerMessage v-if="shouldDisplayCashBanner" />
          <SalesHeaderContent v-if="tab === 'Factures'" />
          <SalesPreSalesHeaderContent v-if="tab === 'Devis'" />
        </div>
      </template>

      <template #filters>
        <div v-if="itemsChecked.length > 0" class="annotation-checkbox">
          <uds-input-checkbox :is-checked="areAllChecked" @input="checkAllItems" />
        </div>
      </template>

      <template #panels>
        <SalesMainPanel
          class="page-tables-mainpanel"
          :style="`max-width: ${isSidePanelOpened ? workableWidth - 596 : workableWidth}px`"
        />
        <LazySalesSidePanel v-if="isSidePanelOpened" class="page-tables-sidepanel" />
      </template>

      <template #first-item>
        <template v-if="tab === 'Factures'">
          <SalesFirstFacturesItem />
        </template>
        <template v-if="tab === 'Devis'">
          <SalesFirstDevisItem />
        </template>
        <template v-if="tab === 'Brouillons'">
          <SalesFirstDraftItem />
        </template>
      </template>
    </LayoutMainAndSidePanel>
  </div>
</template>

<script setup lang="ts">
import type { ReadFormattedAccountingTransaction } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
import { useCommonStore } from "~/stores/common";
import { JSONParseObjectValues } from "~/utils/json";

const { formatDate } = useDateFormat();
const salesStore = useSalesStore();
const exerciceStore = useExerciceStore();
const societyStore = useSocietyStore();
const categoryStore = useCategoryStore();
const thirdPartyStore = useThirdPartiesStore();
const partnerStore = usePartnerStore();
const unlockedStore = useUnlockedStore();
const connectorStore = useConnectorStore();
const commonStore = useCommonStore();

const isAccruals = computed(() => societyStore.isAccruals);
const idSociety = computed(() => societyStore?.society?.id ?? 0);

definePageMeta({
  permissions: [EPermissions.GESCOM],
});

useSeoMeta({
  title: "Ventes",
});

const sales = ref(null);
const { workableWidth } = getElementWidth(sales);

const isSidePanelOpened = computed(() => {
  return (
    salesStore.queryProperties.itemId !== null || salesStore.queryProperties.checkedIds.length > 0
  );
});
const tab = computed(() => salesStore.queryProperties.tab);

const hasAnyItem = computed(() => {
  return salesStore?.hasAnyItem;
});

const shouldDisplayCashBanner = computed<boolean>(() => {
  return (
    !isDefined(connectorStore.stripe) &&
    !isDefined(
      societyStore.visualizedInformations.find(
        (vi) => vi.key === VisualizedInformationKey.CASH_IN_YOUR_CUSTOMERS_MESSAGE_CLOSED,
      ),
    )
  );
});

onMounted(async () => {
  // Check for a common query
  const route = useRoute();
  const query = route.query as { [key: string]: string };
  const hash = route.hash;

  const parsedQuery = JSONParseObjectValues(query);
  const storedQuery = salesStore.queryProperties;
  const commonQuery = commonStore.queryProperties;
  const newQueryProperties = {
    ...defaultSalesQueryProperties.value,
    ...parsedQuery,
  };

  const startDateCondition =
    !isDefined(parsedQuery.startDate) &&
    isDefined(commonQuery.startDate) &&
    (!isDefined(storedQuery.startDate) || storedQuery.startDate !== commonQuery.startDate);
  const endDateCondition =
    !isDefined(parsedQuery.endDate) &&
    isDefined(commonQuery.endDate) &&
    (!isDefined(storedQuery.endDate) || storedQuery.endDate !== commonQuery.endDate);
  if (startDateCondition || endDateCondition) {
    const newLink = useBuildRouteFromQueryPropertiesComposable(
      defaultSalesQueryProperties.value,
      {
        ...newQueryProperties,
        ...(startDateCondition
          ? {
              startDate: commonQuery.startDate,
            }
          : {}),
        ...(endDateCondition
          ? {
              endDate: commonQuery.endDate,
            }
          : {}),
      },
      "/sales",
    );
    await navigateTo(`${newLink}${hash}`);
  }

  // handle query
  handleQuery();

  // Fetch secondary data if they dont already exists
  await Promise.all([
    // categoryStore.fetchSocietyCategories(idSociety.value),
    ...(categoryStore.immobilizationCategories.length === 0
      ? [categoryStore.fetchImmobilizationCategories()]
      : []),
    ...(categoryStore.types.length === 0 ? [categoryStore.fetchCategoryTypes()] : []),
    ...(thirdPartyStore.allThirdParties.length === 0
      ? [thirdPartyStore.fetchThirdPartiesAndGenericThirdParties()]
      : []),
    ...(partnerStore.partners.length === 0 ? [partnerStore.fetchSocietyPartners()] : []),
  ]);
});

onUpdated(() => {
  handleQuery();
});

async function handleQuery() {
  const route = useRoute();
  const query = route.query as { [key: string]: string };
  const parsedQuery = JSONParseObjectValues(query);
  const hash = route.hash;

  const newQueryProperties = {
    ...defaultSalesQueryProperties.value,
    ...parsedQuery,
  };

  const oldQueryProperties = cloneDeep(toRaw(salesStore.queryProperties));

  if (!isAccruals.value && newQueryProperties.checkedIds.length !== 0) {
    return navigateTo(
      useBuildRouteFromQueryPropertiesComposable(
        defaultSalesQueryProperties.value,
        {
          ...newQueryProperties,
          checkedIds: [],
        },
        "/sales",
      ),
    );
  }

  // check if filters should be added to the query
  if (
    !isDefined(newQueryProperties.tab) ||
    (!isDefined(newQueryProperties.startDate) &&
      isDefined(exerciceStore.defaultExercice.startDate)) ||
    (!isDefined(newQueryProperties.endDate) && isDefined(exerciceStore.defaultExercice.endDate))
  ) {
    const newLink = useBuildRouteFromQueryPropertiesComposable(
      defaultSalesQueryProperties.value,
      {
        ...newQueryProperties,
        ...(!isDefined(newQueryProperties.tab) ? { tab: "Factures" } : {}),
        ...(!isDefined(newQueryProperties.startDate) &&
        isDefined(exerciceStore.defaultExercice.startDate)
          ? {
              startDate: formatDate(exerciceStore.defaultExercice.startDate, "YYYY-MM-DD"),
            }
          : {}),
        ...(!isDefined(newQueryProperties.startDate) &&
        isDefined(salesStore.queryProperties.startDate)
          ? {
              startDate: formatDate(salesStore.queryProperties.startDate, "YYYY-MM-DD"),
            }
          : {}),
        ...(!isDefined(newQueryProperties.endDate) &&
        isDefined(exerciceStore.defaultExercice.endDate)
          ? {
              endDate: formatDate(exerciceStore.defaultExercice.endDate, "YYYY-MM-DD"),
            }
          : {}),
        ...(!isDefined(newQueryProperties.endDate) && isDefined(salesStore.queryProperties.endDate)
          ? {
              endDate: formatDate(salesStore.queryProperties.endDate, "YYYY-MM-DD"),
            }
          : {}),
      },
      "/sales",
    );
    return navigateTo(`${newLink}${hash}`);
  }

  salesStore.queryProperties = newQueryProperties;

  const hasForceRefreshHash = hash === "#forceRefresh";

  const promises: Promise<any>[] = [];

  const hasANewQueryPropertyAboutFiltering =
    newQueryProperties.tab !== oldQueryProperties.tab ||
    newQueryProperties.toAnnotate !== oldQueryProperties.toAnnotate ||
    newQueryProperties.startDate !== oldQueryProperties.startDate ||
    newQueryProperties.endDate !== oldQueryProperties.endDate ||
    newQueryProperties.page !== oldQueryProperties.page ||
    newQueryProperties.limit !== oldQueryProperties.limit ||
    newQueryProperties.orderBy !== oldQueryProperties.orderBy ||
    newQueryProperties.descending !== oldQueryProperties.descending ||
    newQueryProperties.search !== oldQueryProperties.search ||
    newQueryProperties.status !== oldQueryProperties.status;
  if (hasANewQueryPropertyAboutFiltering || hasForceRefreshHash) {
    promises.push(salesStore.fetchSalesItems());
    promises.push(salesStore.fetchStats());
  }

  const hasANewQueryPropertyAboutHasAnyItem = newQueryProperties.tab !== oldQueryProperties.tab;
  if (hasANewQueryPropertyAboutHasAnyItem || hasForceRefreshHash) {
    promises.push(salesStore.fetchHasAnyItem());
  }

  await Promise.all(promises);

  // remove hash, so #forceRefresh can be reused again (the watcher would not be triggered otherwise)
  if (hasForceRefreshHash) {
    unlockedStore.fetchSaleDocuments(idSociety.value);
    const link = useBuildRouteFromQueryPropertiesComposable(
      defaultSalesQueryProperties.value,
      {
        ...salesStore.queryProperties,
      },
      "/sales",
    );
    await navigateTo(link);
  }
}

// Checked
const itemsChecked = computed<ReadFormattedAccountingTransaction[]>(() => {
  return (salesStore.items ?? []).filter(
    (tr) => tr.id && salesStore.queryProperties.checkedIds.includes(tr.id),
  ) as ReadFormattedAccountingTransaction[];
});

const areAllChecked = computed(() => {
  const items = (salesStore.items as ReadFormattedAccountingTransaction[]) ?? [];
  const countCheckedIds = items.reduce((counter, item) => {
    if ((item.entries ?? []).length < 2 && item.id) counter += 1;
    return counter;
  }, 0);
  return (
    isDefined(salesStore.queryProperties.checkedIds) &&
    countCheckedIds === salesStore.queryProperties.checkedIds.length
  );
});

async function checkAllItems() {
  const newCheckedIds: number[] = [];
  if (!areAllChecked.value) {
    const items = (salesStore.items as ReadFormattedAccountingTransaction[]) ?? [];

    items.forEach((item) => {
      if ((item.entries ?? []).length < 2 && item.id) {
        newCheckedIds.push(item.id);
      }
    });
  }
  await navigateTo(
    useBuildRouteFromQueryPropertiesComposable(
      defaultSalesQueryProperties.value,
      {
        ...salesStore.queryProperties,
        checkedIds: newCheckedIds,
      },
      "/sales",
    ),
  );
}
</script>

<style lang="scss" scoped>
.sales-top-container {
  width: 100%;
  display: flex;
  gap: $uds-spacing-2;
  flex-direction: column;
}

:deep(.hi-ctaButton) {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  margin-bottom: $uds-spacing-2 !important;
  div:not(.annotate_counter) {
    button {
      margin-left: $uds-spacing-2;
    }
  }
}
</style>

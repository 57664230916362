<template>
  <div ref="el">
    <div class="negative-margin-top">
      <template v-if="!isLoading">
        <template v-if="hasItems">
          <div v-for="(pGroup, groupIndex) in salesGroups" :key="groupIndex" class="table-group">
            <div
              :key="groupIndex"
              class="main-panel-group-name"
              :class="{ '--space-left': hasCheckedItems }"
            >
              <template v-if="workableWidth < 555 && isSidePanelOpened">
                {{ pGroup.date ? dayjs(pGroup.date).format("MMM YYYY") : pGroup.name }}
              </template>
              <template v-else>
                {{ pGroup.name }}
              </template>
            </div>

            <template v-for="salesItem in pGroup.salesItems" :key="'itemLine' + salesItem.id">
              <CommonsTablesItemLine
                :item="salesItem"
                :config="config"
                :is-loading="false"
                :item-selected="
                  selectedItemId === salesItem.id ||
                  salesStore.queryProperties.checkedIds.includes(salesItem.id ?? -1)
                "
                :class="{
                  '--disabled': !canCheckItem(salesItem) && isMultipleMode,
                }"
                @add-item-to-query="
                  isAccruals ? addItemIdToQueryAccrual(salesItem) : addItemIdToQuery(salesItem.id)
                "
              >
                <template #cells="{ column }">
                  <SalesMainPanelCellsMainCell
                    v-if="column.type === EColumnType.MAIN"
                    :item="salesItem"
                    :style="`width: ${column.width}px;`"
                  />
                  <SalesMainPanelCellsStatusCell
                    v-if="column.type === EColumnType.SPE_STATUS"
                    :item="salesItem"
                    :style="`width: ${column.width}px;`"
                  />
                  <SalesMainPanelCellsTagCell
                    v-if="column.type === EColumnType.SPE_TAG"
                    :item="salesItem"
                    :style="`width: ${column.width}px;`"
                  />
                  <SalesMainPanelCellsCategoryCell
                    v-if="column.type === EColumnType.SPE_TEXT"
                    :item="salesItem"
                    :style="`width: ${column.width}px;`"
                  />
                  <template v-if="column.type === EColumnType.SPE_VAT">
                    <SalesMainPanelCellsVatAndAmountCell
                      v-if="!isImportedDocument(salesItem)"
                      :item="salesItem"
                      :style="`width: ${column.width}px;`"
                    />
                    <template v-else>
                      <SalesMainPanelCellsAccrualsVatAndAmountCell
                        v-if="isAccruals"
                        :item="salesItem"
                        :style="`width: ${column.width}px;`"
                      />
                      <CommonsTablesItemLineDateTextCell
                        v-else
                        :item="salesItem"
                        :column="column"
                        :style="`width: ${column.width}px;`"
                      />
                    </template>
                  </template>
                </template>
              </CommonsTablesItemLine>
            </template>
          </div>
        </template>
        <CommonsNoData v-else :text="'Aucune donnée'" />
      </template>
      <template v-else>
        <div class="main-panel-group-name" />
        <CommonsTablesItemLinePlaceholder :config="config" with-borders />
      </template>
    </div>
    <CommonsExportImport
      v-show="!isLoading && tab !== 'Brouillons'"
      state="sales"
      :export-key="tab === 'Factures' ? 'SALE_INVOICE' : 'ESTIMATE'"
      :has-items="hasItems"
      :no-import="tab === 'Devis'"
      @open-new-item="openModalImportInvoice"
    />
  </div>
</template>

<script setup lang="ts">
import { useModal } from "vue-final-modal";
import cloneDeep from "lodash-es/cloneDeep";
import type { ReadFormattedAccountingTransaction } from "@silexpert/core";
import type { ConfigColumn } from "~/@types/localTypes/table";
import { EColumnType } from "~/@types/localTypes/table";
import TypeImportModal from "@/components/commons/modals/TypeImportModal.vue";
import ImportInvoice from "@/components/commons/modals/ImportInvoiceModal.vue";

const salesStore = useSalesStore();
const societyStore = useSocietyStore();
const dayjs = useDayjs();

const usedWidth = 8; // 1 (grey border) + 3 (selection line) + 3 + 1
const el = ref(null);
const { workableWidth } = getElementWidth(el);

const cellsWorkableWidth = computed(() => {
  return workableWidth.value - usedWidth;
});

type salesGroup = {
  name: string;
  date: number | null;
  salesItems: SalesItem[];
};

const isAccruals = computed<boolean>(() => societyStore.isAccruals);
const isLoading = computed<boolean>(() => salesStore.isLoading);
const tab = computed(() => salesStore.queryProperties.tab);

const hasCheckedItems = computed(() => {
  return salesStore.queryProperties.checkedIds.length > 0;
});

const isSidePanelOpened = computed(() => {
  return salesStore.queryProperties.itemId !== null;
});

const config = computed(() => {
  const itemLineConfig = useItemLinesComposable();

  const config = itemLineConfig.getItemLineConfig(cellsWorkableWidth.value, {
    isAccruals: societyStore.isAccruals,
    isInvoiceTab: tab.value === "Factures",
    isDraftTab: tab.value === "Brouillons",
  });

  return config[EItemLineConfig.SALES];
}) as ComputedRef<ConfigColumn[]>;

const selectedItemId = computed<number | null>(() => {
  return salesStore.queryProperties.itemId;
});

const isMultipleMode = computed(() => salesStore.queryProperties.checkedIds.length > 0);

const data = computed(() => {
  return salesStore.items ?? [];
});

const hasItems = computed<boolean>(() => {
  return data.value.length > 0;
});

const salesGroups = computed(() => {
  const salesGroups: salesGroup[] = [];
  let currentGroup: salesGroup = {
    name: "",
    date: null,
    salesItems: [],
  };

  const salesItems = data.value as unknown as SalesItem[];

  for (const index in salesItems) {
    const item = cloneDeep(toRaw(salesItems[index]));
    const itemComp = useSalesComposable(item);
    const groupName = dayjs(itemComp.date()).format("MMMM YYYY");

    if (groupName === currentGroup.name) {
      currentGroup.salesItems.push(item);
    } else {
      if (currentGroup.salesItems.length > 0) {
        salesGroups.push(currentGroup);
      }

      currentGroup = {
        name: groupName,
        date: itemComp.date(),
        salesItems: [item],
      };
    }
  }
  // add the last group
  if (currentGroup.salesItems.length > 0) {
    salesGroups.push(currentGroup);
  }

  return salesGroups;
});

function isImportedDocument(item: SalesItem) {
  const salesItem = useSalesComposable(item);
  return salesItem.isImportedDocument();
}

function addItemIdToQuery(id: number | undefined) {
  if (isDefined(id)) {
    const link = useBuildRouteFromQueryPropertiesComposable(
      defaultSalesQueryProperties.value,
      {
        ...salesStore.queryProperties,
        itemId: selectedItemId.value === id ? null : (id ?? null),
        checkedIds: selectedItemId.value === id ? [] : [id],
      },
      "/sales",
    );
    return navigateTo(link);
  }
}

function canCheckItem(item: SalesItem): boolean {
  const isFacturesTab = salesStore.queryProperties.tab === "Factures";
  return (
    isFacturesTab && !(((item as ReadFormattedAccountingTransaction).entries ?? []).length > 1)
  );
}

function addItemIdToQueryAccrual(item: SalesItem) {
  if (isDefined(item.id)) {
    let link;
    if (salesStore.queryProperties.checkedIds.length > 0) {
      const canCheck = canCheckItem(item);

      if (canCheck) {
        const checkedIds = salesStore.queryProperties.checkedIds;
        const isChecked = checkedIds.includes(item.id);

        if (isChecked) {
          checkedIds.splice(checkedIds.indexOf(item.id), 1);
        } else {
          checkedIds.push(item.id);
        }

        link = useBuildRouteFromQueryPropertiesComposable(
          defaultSalesQueryProperties.value,
          {
            ...salesStore.queryProperties,
            itemId: null,
            checkedIds,
          },
          "/sales",
        );
      }
    } else {
      link = useBuildRouteFromQueryPropertiesComposable(
        defaultSalesQueryProperties.value,
        {
          ...salesStore.queryProperties,
          itemId: selectedItemId.value === item.id ? null : (item.id ?? null),
        },
        "/sales",
      );
    }
    if (link) return navigateTo(link);
  }
}

async function openModalImportInvoice() {
  if (isAccruals.value) {
    const { open, close } = useModal({
      component: TypeImportModal,
      attrs: {
        onClose() {
          close();
        },
      },
    });
    await open();
  } else {
    const { open, close } = useModal({
      component: ImportInvoice,
      attrs: {
        isSales: true,
        async onClose() {
          close();
          const link = useBuildRouteFromQueryPropertiesComposable(
            defaultSalesQueryProperties.value,
            {
              ...salesStore.queryProperties,
              page: 1,
              limit: 25,
            },
            "/sales",
          );
          await navigateTo(`${link}#forceRefresh`);
        },
        onCancel() {
          close();
        },
      },
    });
    await open();
  }
}
</script>

<template>
  <div class="vat-and-amount-cell-container">
    <div class="vaac-line">
      <uds-amount-currencie :value="amountTTC" :has-symbol="hasSymbol" class="ttc-amount" />
      <template v-if="!isNoTva">ttc</template>
    </div>
    <div v-if="!isNoTva" class="vaac-line --ht">
      {{ vatText }}
    </div>
  </div>
</template>

<script setup lang="ts">
const societyStore = useSocietyStore();
const serverInfoStore = useServerInfoStore();
const utils = useAnnotationUtilsComposable();

const props = defineProps<{
  item: any;
}>();

const entry = computed(() => props.item.entries?.[0] ?? {});
const isNoTva = computed(() => societyStore.isNotSubjectToVat);
const amountTTC = computed(() => {
  return isDefined(props.item.credit) && isDefined(props.item.debit)
    ? props.item.credit - props.item.debit
    : 0;
});
const hasSymbol = computed<boolean>(() => (amountTTC.value ?? 0) < 0);

const vatText = computed(() => {
  if (isDefined(props.item.entries) && props.item.entries.length > 1) {
    return "Plusieurs TVAs";
  }

  const vatId = utils.getVatIdFromFormEntryVat(entry.value.vat, entry.value);
  if (!isDefined(vatId)) {
    return "Choisir la TVA";
  }

  const vats = serverInfoStore.vats ?? [];
  const matchingVat = vats.find((vat) => vat.idTva === vatId);
  const vatNameString = matchingVat?.name ?? "";

  const vatAmount = getVatAmount({
    entry: entry.value,
    amount: props.item.amount ?? 0,
    roundToCents: true,
  });

  if (!isDefined(vatAmount)) {
    return `(${vatNameString})`;
  }

  const formattedAmount =
    isDefined(props.item.credit) && isDefined(props.item.debit)
      ? numberToFormattedAmount(
          props.item.credit - props.item.debit + (props.item.isAsset ? vatAmount : -vatAmount),
        )
      : null;
  const vatAmountString = isDefined(formattedAmount)
    ? `${formattedAmount.symbol === "+" ? "" : "-"} ${formattedAmount.integerValue},${
        formattedAmount.centValue
      }`
    : "--";
  return `${vatAmountString} € ht`;
});
</script>

<style lang="scss" scoped>
.vat-and-amount-cell-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;

  padding-right: $uds-spacing-2;
  .vaac-line {
    display: flex;
    gap: $uds-spacing-1;
    font-size: 18px;
    align-items: center;
    &.--is-canceled {
      text-decoration: line-through;
    }
    .ttc-amount {
      font-weight: $uds-weight-500;
      font-size: 18px;
      color: $uds-neutral-900;
    }
    &.--ht {
      color: $uds-neutral-800;
    }
    .ht-amount {
      color: $uds-neutral-800;
    }
  }
}
</style>

<template>
  <div class="first-item-container">
    <div class="first-item-text">
      <div class="uds-headers --h2">Créez votre premier devis</div>
      <div class="uds-paragraph first-item-subtitle">La gestion de vos devis simplifiée.</div>
    </div>
    <div class="first-item-svg">
      <div>
        <img v-if="isComptalib()" src="~/assets/img/firstSales1Comptalib.png" >
        <SvgOnBoardingDocumentSvg v-else />
        <div class="uds-paragraph">
          Créez et envoyez vos devis ou bons de commande en quelques clics
        </div>
      </div>
      <div>
        <img v-if="isComptalib()" src="~/assets/img/firstSales4Comptalib.png" >
        <SvgOnBoardingDocumentWaitSvg v-else />
        <div class="uds-paragraph">
          Suivez en un coup d'oeil le statut de vos devis et bons de commande
        </div>
      </div>
      <div>
        <img v-if="isComptalib()" src="~/assets/img/firstSales5Comptalib.png" >
        <SvgOnBoardingDocumentRotationSvg v-else />
        <div class="uds-paragraph">Transformez directement vos devis acceptés en facture</div>
      </div>
    </div>
    <div class="first-item-buttons">
      <uds-main-button @click="navigateTo(`/sales/form/${EstimateInvoiceType.DEVIS}`)"
        >Commencer</uds-main-button
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { EstimateInvoiceType } from "@silexpert/core";
const { isComptalib } = useBrandsComposable();
</script>

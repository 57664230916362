<template>
  <div class="vat-and-amount-cell-container">
    <div class="vaac-line" :class="{ '--is-canceled': isCanceled }">
      <uds-amount-currencie :value="amountTTC" :has-symbol="amountTTC < 0" class="ttc-amount" />
      <template v-if="!isNoTva">ttc</template>
    </div>
    <div v-if="!isNoTva" class="vaac-line --ht">
      <uds-amount-currencie :value="amountHT" :has-symbol="amountHT < 0" class="ht-amount" />
      ht
    </div>
  </div>
</template>

<script setup lang="ts">
import { InvoiceStatus } from "@silexpert/core";
import { devideBy100 } from "~/utils/amounts";

const societyStore = useSocietyStore();

const props = defineProps<{
  item: any;
}>();

const salesItem = computed(() => useSalesComposable(props.item));

const status = computed(() => {
  if (salesItem.value.isFactures() && isDefined(props.item.estimatesInvoice)) {
    return (props.item as FacturesItem).estimatesInvoice?.status ?? null;
  }
  return null;
});

const isCanceled = computed(() => {
  return isDefined(status.value) && status.value === InvoiceStatus.CANCELED;
});

const amountHT = computed(() => {
  let totalHt = 0;
  if (salesItem.value.isFactures()) {
    totalHt = props.item?.estimatesInvoice?.totalTaxExcluded ?? 0;
  }
  if (salesItem.value.isDevisOrBrouillons()) {
    totalHt = props.item?.totalTaxExcluded ?? 0;
  }

  return devideBy100(totalHt) as number;
});

const amountTTC = computed(() => {
  const value = salesItem.value.totalNetCash() ?? 0;
  return value;
});

const isNoTva = computed(() => societyStore.isNotSubjectToVat);
</script>

<style lang="scss" scoped>
.vat-and-amount-cell-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;

  padding-right: $uds-spacing-2;
  .vaac-line {
    display: flex;
    gap: $uds-spacing-1;
    font-size: 18px;
    align-items: center;
    &.--is-canceled {
      text-decoration: line-through;
    }
    .ttc-amount {
      font-weight: $uds-weight-500;
      font-size: 18px;
      color: $uds-neutral-900;
    }
    &.--ht {
      color: $uds-neutral-800;
    }
    .ht-amount {
      color: $uds-neutral-800;
    }
  }
}
</style>

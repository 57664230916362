<template>
  <div class="first-item-container">
    <div class="first-item-text">
      <div class="uds-headers --h2">Facturez votre premier client</div>
      <div class="uds-paragraph first-item-subtitle">
        Un outil de facturation complet qui vous permet de créer et gérer simplement vos factures.
      </div>
    </div>
    <div class="first-item-svg">
      <div>
        <img v-if="isComptalib()" src="~/assets/img/firstSales1Comptalib.png" >
        <SvgOnBoardingDocumentSvg v-else />
        <div class="uds-paragraph">Créez et envoyez vos factures en quelques clics</div>
      </div>
      <div>
        <img v-if="isComptalib()" src="~/assets/img/firstSales2Comptalib.png" >
        <SvgOnBoardingDocumentBillingSvg v-else />
        <div class="uds-paragraph">
          Suivez en un coup d'oeil votre facturation et relancez vos clients pour les factures
          impayées
        </div>
      </div>
      <div>
        <img v-if="isComptalib()" src="~/assets/img/firstSales3Comptalib.png" >
        <SvgOnBoardingDocumentStripeSvg v-else />
        <div class="uds-paragraph">
          Encaissez plus rapidement avec des liens de paiement associés aux factures par le biais de
          Stripe
        </div>
      </div>
    </div>
    <div class="first-item-buttons">
      <uds-main-button @click="navigateTo(`/sales/form/${EstimateInvoiceType.FACTURE}`)"
        >Commencer</uds-main-button
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { EstimateInvoiceType } from "@silexpert/core";
const { isComptalib } = useBrandsComposable();
</script>
